<template>
  <div id="login" style="height: 100%; overflow: hidden">
    <v-img
      src="@/assets/Logo-Radio-Trunk_negro.png"
      style="margin: 0px auto; margin-top: 50px"
      max-width="280px"
      min-width="220px"
    >
    </v-img>
    <p id="welcomeMsg">BIENVENIDO A LA TIENDA DE VENTA EXPRÉS</p>
    <p id="welcomeMsg2">
      DONDE PODRÁS COMPRAR Y AUTOCONFIGURAR TUS RADIOS CON LOS GRUPOS Y 
      USUARIOS QUE DESEES POR EL TIEMPO QUE REQUIERAS
    </p>


    <v-carousel
    cycle
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="img in 9"
      :key="img"
      :src="`https://radiotrunk.com/12-${img}.png`"
      contain
    >
    </v-carousel-item>
  </v-carousel>


    <div style="margin: 0px auto; width: 90%; height: 100%">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="black"
        centered
        fixed-tabs
        slider-size="2"
        class="mt-12"
        style="z-index:9999"
      >
        <v-tab v-for="item in items" :key="item" style="z-index:9999">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" style="background-color: transparent; z-index:9999">
        <v-tab-item :value="0" style="z-index:9999">
          <LogIn />
        </v-tab-item>
        <v-tab-item :value="1" style="z-index:9999">
          <SignIn />
        </v-tab-item>
        <v-tab-item :value="2" style="z-index:9999">
          <ContactUs/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import LogIn from "../components/LogInInputs.vue";
import SignIn from "../components/SignInInputs.vue";
import ContactUs from "../components/contactUs.vue"
export default {
  data() {
    return {
      slides:[
        {
          src:'https://cdn.shopify.com/s/files/1/0589/5163/1028/products/radio1mes.jpg?v=1633530607'
        },
        {
          src:'https://cdn.shopify.com/s/files/1/0589/5163/1028/products/radio6meses.jpg?v=1631917723'
        },
        {
          src:'https://cdn.shopify.com/s/files/1/0589/5163/1028/products/1MES.png?v=1635791847'
        }
      ],
      tab: 0,
      items: ["Iniciar Sesión", "Crear cuenta","Contáctanos"],
    };
  },
  computed: {
    size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "220px";
        case "sm":
          return "400px";
        case "md":
          return "500px";
        case "lg":
          return "600px";
        case "xl":
          return "800px";
      }
      return "1000px";
    },
  },
  components: {
    LogIn,
    SignIn,
    ContactUs
  },
};
</script>

<style scoped>
/* Helper classes */

/* .basil--text {
  color: #065fbf !important;
}
#login,
#color {
  background: rgb(0, 93, 255);
  background: linear-gradient(
    0deg,
    rgba(0, 93, 255, 1) 0%,
    rgba(21, 40, 71, 1) 100%
  );
} */
#welcomeMsg {
  width: 50%;
  font-size: 25px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  color: #005484;
  font-weight: bold;
  text-shadow: 1px 3px 0px #ccc;
}
#welcomeMsg2 {
  width: 50%;
  font-size: 15px;
  margin: 0 auto;
  text-align: center;
  color: #005484;
  font-weight: bold;
  text-shadow: 1px 1px 0px #ccc;
  
}
@media (max-width: 375px) {
  #welcomeMsg {
    width: 100%;
    font-size: 20px;
  }
  #welcomeMsg2{
    width: 100%;
    font-size: 15px;
  }
}
</style>