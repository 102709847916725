<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="background: #fff"
  >
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on" id="forgot-pass" style="width:200px;margin: 0 auto;"
        ><p class="black--text" style="text-align:center" >¿Olvidaste tu contraseña?</p></a
      >
    </template>
    <v-card style="border: #fff 2px solid;background: rgb(0, 93, 255);background: linear-gradient(0deg,rgba(0, 93, 255, 1) 0%,rgba(21, 40, 71, 1) 100%);">
        <h2 class="white--text" style="text-align:center;">Recuperacion de contraseña</h2>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
            dark
              outlined
              rounded
              label="Correo electronico"
              class="mt-12"
              :value="recoverField.email"
              @input="updateRecoverEmail($event)"
              @keyup.enter="recoverPassword();dialog = false"
              clearable
            />
          </v-col>
        </v-row>    
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="dialog = false;updateRecoverEmail('')"> Cerrar </v-btn>
        <v-btn color="white" text @click="recoverPassword();dialog = false">
          Recuperar contraseña
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  data: () => ({
    dialog: false,
  }),
  computed:{
      ...mapState('LogIn',['recoverField'])
  },
  methods:{
      ...mapActions('LogIn',['recoverPassword']),
      ...mapMutations('LogIn',['updateRecoverEmail'])
  }
};
</script>

<style>
</style>