<template>
  <div style="display: grid; height: 100%" id="logininputs">
    <!-- <v-img
      v-if="this.$vuetify.breakpoint.name=='xl'||this.$vuetify.breakpoint.name=='lg'"
      src="@/assets/imagen_radio1.png"
      style=" position:absolute;left:15px"
      width="230px"
      height="312px"
    >
    </v-img> -->
    <v-img
      src="@/assets/imagen_radio2.png"
      v-if="
        this.$vuetify.breakpoint.name == 'xl' ||
        this.$vuetify.breakpoint.name == 'lg'
      "
      style="position: absolute; right: 15px"
      width="230px"
      height="312px"
    >
    </v-img>
    <v-text-field
      :style="'min-width:' + size + ';  margin: 0 auto;'"
      outlined
      rounded
      :value="LogIn.email"
      @input="updateLogInEmail($event)"
      clearable
      class="mt-12"
      label="Correo"
    ></v-text-field>
    <v-text-field
      :style="'min-width:' + size + ';  margin: 0 auto;'"
      outlined
      rounded
      :value="LogIn.password"
      @input="updateLogInPassword($event)"
      @keyup.enter="LogInAccount"
      clearable
      label="Contraseña"
      type="password"
    ></v-text-field>

    <RecoverPass />

    <v-btn style="margin: auto" x-large rounded outlined @click="LogInAccount"
      >Entrar</v-btn
    >
    <!-- <SocialButtonLogin
      color="blue"
      social="facebook"
      :dark="true"
      id="socialbtn"
      :href='this.environment()+"/users/facebook/signup"' 
    /> -->
    <SocialButtonLogin
      color="red"
      social="google"
      :dark="true"
      id="socialbtn"
      :href='this.environment()+"/users/google/signup"'
    /> 
    <v-img
      src="@/assets/imagen_radio1.png"
      v-if="
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm'
      "
      style="margin: 0 auto; margin: 20px"
      height="400px"
    >
    </v-img>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import RecoverPass from "./recoverPassword.vue";
import SocialButtonLogin from "./socialButtonLogin.vue";
export default {
  computed: {
    ...mapState("LogIn", ["LogIn"]),
    size() {
      console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "220px";
        case "sm":
          return "400px";
        case "md":
          return "500px";
        case "lg":
          return "600px";
        case "xl":
          return "800px";
      }
      return "1000px";
    },
    
    
  },
  methods: {
    ...mapMutations("LogIn", ["updateLogInEmail", "updateLogInPassword"]),
    ...mapActions("LogIn", ["LogInAccount","openSocialDialog"]),
    environment(){
    if(process.env.NODE_ENV === 'development'){
        return 'http://localhost:5051/api/v1'
    }
    else if(process.env.NODE_ENV === 'production'){
        return 'https://productioncrmback.radiotrunk.com/api/v1'
    }
    }
  },
  components: {
    RecoverPass,
    SocialButtonLogin,
  },
};
</script>

<style>
#socialbtn {
  margin: 0px auto;
  min-width: 250px;
  margin-top: 15px;
}
#forgot-pass {
  text-decoration: none;
  text-align: center;
}
#logininputs {
  padding: 15px;
}
</style>