<template>
  <div style="display: grid" id="logininputs">
    <v-text-field
      rounded
      outlined
      class="mt-12"
      label="Nombre"
      :value="name"
      @input="updateInputs(['name',$event])"
      :style="'min-width:' + size + ';  margin: 0 auto;'"
    >
    </v-text-field>
    <v-text-field
      rounded
      outlined
      label="Correo"
      :value="email"
      type="email"
      @input="updateInputs(['email',$event])"
      :style="'min-width:' + size + ';  margin: 0 auto;'"
    >
    </v-text-field>
    <v-text-field
      rounded
      outlined
      label="Telefono"
      :value="phone"
      type="number"
      @input="updateInputs(['phone',$event])"
      :style="'min-width:' + size + ';  margin: 0 auto;'"
    >
    </v-text-field>
    <v-textarea
      outlined
      :style="'min-width:' + size + ';  margin: 0 auto;'"
      @input="updateInputs(['comment',$event])"
      rounded
      :value="comment"
      label="Mensaje"
      counter="500"
      maxlength="500"
    ></v-textarea>
    <v-btn style="margin: 0px auto" rounded outlined width="150px" @click="sendContacMessage">
      Enviar
    </v-btn>
  
  
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  computed: {
    size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "220px";
        case "sm":
          return "400px";
        case "md":
          return "500px";
        case "lg":
          return "600px";
        case "xl":
          return "800px";
      }
      return "1000px";
    },
    ...mapState('ContactUs',['name','phone','comment','email'])
  },
  methods:{
    ...mapActions('ContactUs',['sendContacMessage']),
    ...mapMutations('ContactUs',['updateInputs'])
  }
};
</script>

<style>
</style>