<template>
  <v-btn :color="color" rounded :dark="dark" @click="changeUrl(href)">
      <v-icon >
          mdi-{{social}}
      </v-icon>
      {{social.toUpperCase()}}
  </v-btn>
</template>

<script>
export default {
    props:{
        social: String,
        dark: Boolean,
        color: String,
        href: String
    },
    methods:{
        changeUrl(value){
            location.replace(value)
        }
    }
}
</script>

<style scoped>
</style>