<template>
  <v-dialog :value="DialogPrivacy" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Politica de privacidad
      </v-card-title>

      <v-card-text>
        Los datos recolectados por este sistema son unica y exclusivamente 
        para poder brindarle la atención correspondiente a los pedidos que 
        realice a través del mismo, al crear una cuenta en este sistema 
        acepta que podamos usar su informacion con el fin de brindarle un servicio
        de calidad.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="SignInAccount()"> Acepto </v-btn>
        <v-btn color="error" text @click="closePrivacyDialog()"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState,mapActions, mapMutations } from 'vuex';
export default {
    computed:{
        
        ...mapState('SignIn',['DialogPrivacy'])
    },
    methods:{
        ...mapActions('SignIn',['SignInAccount']),
        ...mapMutations('SignIn',['closePrivacyDialog'])
    }
};
</script>

<style>
</style>