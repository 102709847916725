<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="6" sm="6"
        ><v-text-field
          outlined
          rounded
          clearable
          :value="SignIn.firstname"
          @input="updateSignInFirstName($event)"
          label="Nombre"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="6"
        ><v-text-field
          outlined
          rounded
          clearable
          :value="SignIn.lastname"
          @input="updateSignInLastName($event)"
          label="Apellido"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6"
        ><v-text-field
          outlined
          rounded
          clearable
          type="number"
          @input="updateSignInPhoneNumber($event)"
          :value="SignIn.phone"
          label="Telefono"
        ></v-text-field>
      </v-col> 
      <v-col cols="12" sm="12" md="6"
        ><v-text-field
          outlined
          rounded
          clearable
          :value="SignIn.email"
          @input="updateSignInEmail($event)"
          label="Correo"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6"
        ><v-text-field
          outlined
          rounded
          clearable
          :value="SignIn.password"
          @input="updateSignInPassword($event)"
          type="password"
          label="Contraseña"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6"
        ><v-text-field
          outlined
          rounded
          clearable
          :value="SignIn.passwordVal"
          @input="updateSignInPasswordVal($event)"
          type="password"
          label="Confirmar contraseña"
        ></v-text-field>
      </v-col>
      </v-row>
      <AddressFields
        :address1="SignIn.address.address"
        :address1Fn="updateSignInAddress"
        :address2="SignIn.address.address2"
        :address2Fn="updateSignInAddress2"
        :province="SignIn.address.province"
        :provinceFn="updateSignInProvince"
        :country="SignIn.address.country"
        :countryFn="updateSignInCountry"
        :zip="SignIn.address.zip"
        :zipFn="updateSignInZip"
        :city="SignIn.address.city"
        :cityFn="updateSignInCity"
        :dark="false"
      />
      <PrivacyDialog/>
    <v-row align="center" justify="center">
      <v-col cols="8" align="center" justify="center">
        <v-btn x-large rounded outlined @click="openPrivacyDialog()">Crear</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddressFields from "./addressInputs.vue"
import PrivacyDialog from "./privacyDialog.vue"
export default {
  components:{
    AddressFields,
    PrivacyDialog
  },
  computed: {
    ...mapState('SignIn',["SignIn"]),
  },
  methods: {
    ...mapMutations('SignIn',[
      "updateSignInProvince",
      "updateSignInCountry",
      "updateSignInZip",
      "updateSignInCity",
      "updateSignInFirstName",
      "updateSignInLastName",
      "updateSignInPhoneNumber",
      "updateSignInEmail",
      "updateSignInPassword",
      "updateSignInPasswordVal",
      "updateSignInAddress",
      "updateSignInAddress2",
      "openPrivacyDialog"
    ]),
    ...mapActions('SignIn',['SignInAccount'])
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>